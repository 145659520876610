<template>
  <l-default>
    <OTitle>Bitcoin Checkout</OTitle>
    <OCRow class="u-mb-3">
      <div>{{email}}</div>
      <div class="u-ml-auto">{{credits}} credits</div>
    </OCRow>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive">
      <CBuyButton :credits="Number(parameters.credits)" :cost="Number(parameters.amount)"
                  :always="isAlways" :isLink="false" class="c-grid__item"></CBuyButton>

      <div v-if="!this.loading">
        <div class="c-grid__item">
          Send <span style='color:#ff0000;font-size: 180%'><strong>EXACTLY</strong></span> {{btcPrice}} BTC
        </div>

        <div class="c-grid__item">
          <form @submit="submit">
            <button type="submit" class="pay-button">PAY</button>
          </form>
        </div>

        <div class="c-grid__item">
          <OFormError :message="error"></OFormError>
        </div>
      </div>
    </div>

  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters, mapMutations } from 'vuex'
import OCRow from '../../objects/containers/OCRow'
import CBuyButton from '../../components/CBuyButton'
import OFormError from '../../objects/forms/OFormError'

const getApi = () => import('@/services/api')

export default {
  name: 'PageBitcoinCheck',
  components: {
    LDefault,
    OTitle,
    CBuyButton,
    OCRow,
    OFormError
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      credits: 'getCredits',
      email: 'getEmail',
      password: 'getPassword',
      isActive: 'isActive',
      phones: 'getPhones'
    }),
    parameters: function () {
      return this.$route.query
    },
    isAlways: function () {
      return false
    }
  },
  data: () => ({ loading: true, btcPrice: null, error: null }),
  async mounted () {
    const api = await getApi()
    const result = await api.bitprice({
      user: this.$store.getters.getEmail,
      pass: this.$store.getters.getPassword,
      credits: this.parameters.credits
    })
    if (result.success) {
      this.btcPrice = result.btc
      this.loading = false
      return
    }
    if (result.error) {
      this.setGlobalMessage(result)
    }
  },
  methods: {
    ...mapMutations({
      setGlobalMessage: 'setMessage'
    }),
    async submit (event) {
      event.preventDefault()

      const api = await getApi()
      const result = await api.bitcoinAddress({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        credits: this.parameters.credits
      })
      if (result.error) {
        // TODO
        this.setGlobalMessage(result)
        this.error = result.message
        return
      }

      const url = '/bitcoin-pay?credits=' + this.parameters.credits + '&amount=' +
          this.parameters.amount + '&btc=' + result.btc + '&address=' + result.address
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss">
  .pay-button {
    background: $primary;
    color: #fff;
    font-size: 1.15em;
    border-radius: 4px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    max-width: 750px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: none;
    vertical-align: top;
    cursor: pointer;
    overflow: hidden;
  }

  .c-grid {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 0 1rem;
  }

  .c-grid__title {
    margin: 0;
    padding: 0.25em 0;
    text-transform: uppercase;
  }

  .c-grid__item {
    margin: 0 0 0.5rem;
  }

  .u-ml-auto {
    margin-left: auto;
  }

  .u-mb-3 {
    margin-bottom: 1.5rem;
  }
</style>
